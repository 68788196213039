
/* Text shadow for better visibility over images */
.text-shadow-lg {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
}


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Inter', system-ui, -apple-system, sans-serif;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Inter', system-ui, -apple-system, sans-serif;
    font-weight: 600;
    letter-spacing: -0.025em;
  }
  
  body {
    font-family: 'Inter', system-ui, -apple-system, sans-serif;
    font-weight: 400;
    letter-spacing: -0.01em;
  }
}

/* Remove default margins and paddings */
body, html, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #2d3748;
}

/* Ensure the App component takes full height */
.App {
  min-height: 100vh;
  background-color: #2d3748;
}

/* Button Styling */
.btn {
  @apply px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition;
}

/* Hand-drawn arrow animation */
@keyframes drawing {
  to {
    stroke-dashoffset: 0;
  }
}

/* Handwritten text hover effect */
.handwritten-callout {
  transition: transform 0.3s ease;
}

.handwritten-callout:hover {
  transform: scale(1.05) rotate(-8deg);
}